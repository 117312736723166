import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-739b8fd8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "track-details" }
const _hoisted_2 = { class: "track-details-wrap" }
const _hoisted_3 = {
  class: "t-container",
  style: {"padding":"50px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_single_stage_rank = _resolveComponent("single-stage-rank")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_single_stage_rank, {
          step: _ctx.step,
          pageSize: 1000,
          showButton: false
        }, null, 8, ["step"])
      ])
    ])
  ]))
}