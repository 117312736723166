
import { Options, Vue } from 'vue-class-component';
import { searchToObject } from '@/utils/commom';
import SingleStageRank from '@/components/home/singleStageRank.vue';

@Options({
    components: {
        SingleStageRank,
    },
})
export default class rankDetail extends Vue {
    private step = 1;
    mounted() {
        // const { step } = searchToObject(window.location.search);
        // this.step = Number(step);
        // console.log(this.step);
        // console.log(this.$route.query.step);
    }
}
